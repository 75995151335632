import React from 'react';


import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// #Links
import { ConnetWithUs } from './ConnetWithUSPage/connect_with_us';
import { AboutUS } from './AboutUsPage/abut_us';
import { EphesiansVerse } from './Ephesianstrhee/ephesiansthree';
import { TenKeys } from './10Keys/10keys';
import { SunflowerTool } from './SunflowerTool/sunflowertool';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="connectwithus" element={<ConnetWithUs />} />
      <Route path="aboutus" element={<AboutUS />} />
      <Route path= "ephesiansthree" element={<EphesiansVerse />} />
      <Route path="tenkeys" element={<TenKeys />} />
      <Route path="sunflowerlink" element={<SunflowerTool />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
