import React from "react";
import '../stylesheet/sunflower_tool_link/sunflowertool.css';
import stlLink1 from "../images/for_sunFlowerToolLink/link1.png";
import stlLink2 from "../images/for_sunFlowerToolLink/link2.png";
import ImageLink3 from "./ImageWithLink3.js";
import stlLink4 from "../images/for_sunFlowerToolLink/link4.png";
import stlLink5 from "../images/for_sunFlowerToolLink/link5.png";
import ImageLink6 from "./ImageWithLink6";
import ImageLink7 from "./ImageWithLink7";
import ImageLink8 from "./ImageWithLink8";
import stlLink9 from "../images/for_sunFlowerToolLink/link9.png";
import stlLink10 from "../images/for_sunFlowerToolLink/link10.png";
import stlLink11 from "../images/for_sunFlowerToolLink/link11.png";
import ImageLink12 from "./ImageWithLink12";
import ImageLink13 from "./ImageWithLink13";
import stlLink14 from "../images/for_sunFlowerToolLink/link14.png"
import ImageLink15 from "./ImageWithLink15";
import ImageLink16 from "./ImageWithLink16";
import ImageLink17 from "./ImageWithLink17";
import ImageLink18 from "./ImageWithLink18";
import ImageLink19 from "./ImageWithLink19";
import stlLink20 from "../images/for_sunFlowerToolLink/link20.png";
import stlLink21 from "../images/for_sunFlowerToolLink/link21.png";
import stlLink22 from "../images/for_sunFlowerToolLink/link22.png";
import stlLink23 from "../images/for_sunFlowerToolLink/link23.png";
import stlLink24 from "../images/for_sunFlowerToolLink/link24.png";
import stlLink25 from "../images/for_sunFlowerToolLink/link25.png";
import ImageLink26 from "./ImageWithLink26.js";
import ImageDisplay from './ImageDisplay'; 



export const SunflowerTool = () => {

    return (
        <div style={{ 
            backgroundColor: '#add8e6', // Background color for the whole page
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            paddingTop: "5%",
            color: 'black' // Default text color
        }}>
            <div style={{ marginBottom: "5%" }}>
                <ImageDisplay
                    src={stlLink1} // Use the desired image here
                />
            </div>
            <div style={{ marginBottom: "5%" }}>
                <ImageDisplay
                    src={stlLink2} 
                />
            </div>
            <div style={{marginBottom: "5%" }}>
                <ImageLink3 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink4}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink5}
                />
            </div>
 
            <div style={{marginBottom: "5%" }}>
                <ImageLink6 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink7 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink8 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink9}
                />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink10}
                />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink11}
                />
            </div> 
            <div style={{marginBottom: "5%"}}>
                <ImageLink12 />
            </div>    
            <div style={{marginBottom: "5%"}}>
                <ImageLink13 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink14}
                />
            </div> 
            <div style={{marginBottom: "5%"}}>
                <ImageLink15 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink16 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink17 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink18 />
            </div>
            <div style={{marginBottom: "5%"}}>
                <ImageLink19 />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink20}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink21}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink22}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink23}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink24}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageDisplay
                    src={stlLink25}
                />
            </div>

            <div style={{marginBottom: "5%"}}>
                <ImageLink26 />
            </div>
        </div>
    );   
};
