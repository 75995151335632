// ImageTextComponent.js
import React from 'react';
import stlLink8 from "../images/for_sunFlowerToolLink/img12.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink12 = () => {
  return (
    <div style={{
      backgroundImage: `url(${stLbackground})`,
      backgroundSize: 'cover', // Ensure the background covers the entire div
      backgroundPosition: 'center', // Center the background image
      maxWidth: '100%',
      textAlign: 'center',
      padding: '5% 75px', // Adjusted padding for better spacing
      boxSizing: 'border-box',
    }}>
      <div style={{
        color: "#008000",
        textAlign: 'center',
        marginBottom: '20px', // Space below the header
      }}>
        <h3 style={{marginBottom:'5%'}}>
          <b>View #2: Primary Visual — the Four-Petal Sunflower</b>
        </h3>
        <h5>
          We are invited to move through life in ever deepening friendship with one another.<br />
          The Four-Petal Sunflower* demonstrates the beauty of growing a Kingdom life together.
        </h5>
      </div>

      <div style={{
        display: 'flex',
        maxWidth: '87%',
        height: 'auto',
        margin: '0 auto', // Center the flex container
        alignItems: 'center', // Center contents vertically
        justifyContent: 'space-between', // Distribute space evenly
      }}>
        <div style={{
          flex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          textAlign: 'center',
          padding: '20px', // Add some padding around the text
        }}>
          <div style={{ color: "#008000", maxWidth: '100%'}}>
            <Button style={{textAlign: 'center', marginTop:'90px', marginBottom:'130px' , color:'yellow'}}><b>Four-Petal Sunflower</b></Button> <br></br>
            <small style={{ color: "black", textAlign:'left'}}>
            <b style={{ textAlign:'left'}}>
              *Each Four-Petal Sunflower represents one<br />
              “Sunflower Community” — one growing <br />
              Kingdom community. See Home Page.</b>
          </small>
          </div>
        </div>

        <div style={{
          flex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px', // Add some padding around the image
        }}>
          <img
            src={stlLink8}
            alt="Description"
            style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
          />
        </div>
      </div>
    </div>
  );
};

export default ImageLink12;
