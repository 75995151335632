// ImageTextComponent.js
import React from 'react';
import stlLink13 from "../images/for_sunFlowerToolLink/link13.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink13 = () => {
  return (
    <div style={{
      display: 'flex',
      backgroundImage: `url(${stLbackground})`,
      backgroundSize: 'cover', // Ensure the background covers the entire div
      backgroundPosition: 'center', // Center the background image
      maxWidth: '100%',
      marginLeft: "5%",
      marginRight:'5%',
      height: '50vh', // Full viewport height for consistent spacing
      boxSizing: 'border-box',
      alignItems: 'center', // Center contents vertically
      justifyContent: 'space-between', // Distribute space evenly
    }}>
      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        padding: '0 30px', // Padding around the text container
      }}>
        <div style={{ color: "#008000", maxWidth: '100%' }}>
          <h3 style={{ textAlign: 'center', marginBottom:'5%' }}><b>View #3: Abiding Givers’ Heart</b></h3>
          <h5 style={{ textAlign: 'left' }}>
            Kingdom communities led by the Holy <br></br>
            Spirit will grow in maturity and wholeness.<br />
            They will be blessed with an “Abiding<br />
            Givers’ Heart”.
          </h5>
          <Button style={{ color: 'yellow' }}><b>Immanuel Cycle</b></Button>
        </div>
      </div>

      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px', // Padding around the image container
      }}>
        <img
          src={stlLink13}
          alt="Description"
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink13;
