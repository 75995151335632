
import React from 'react';
import stlLink6 from "../images/for_sunFlowerToolLink/stlda.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";

const ImageLink6 = () => {
  return (
    <div style={{
      display: 'flex',
      backgroundImage: `url(${stLbackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      maxWidth: '100%',
      marginLeft: "5%",
      marginRight:'5%',
      height: '70vh', // Full viewport height
      alignItems: 'center', // Center contents vertically
      justifyContent: 'space-between', // Distribute space evenly
      padding: '0 20px', 
      boxSizing: 'border-box',
    }}>
      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        minHeight: '400px', // Set a minimum height for the text container
      }}>
        <div>
          <h3 style={{ color: "#008000" }}>
            <b>I am passionate about sunflowers<br />
            because God reveals who He is to <br />
            me through sunflowers.</b>
          </h3>
          <p style={{marginBottom:'5%'}}><a href='#'><b><u>Romans 1:20 Matthew 13:23</u></b></a></p>
          <h5 style={{ textAlign: 'left' }}>Three examples:</h5>
          <b><p style={{ textAlign: 'left' , color: "#008000"  }}>
            One small sunflower seed can produce a tall<br />
            flourishing plant with a beautiful large flower. It<br />
            can be enjoyed for its beauty, ecological benefits,<br />
            and economic products. This shows me how 
            <h5 style={{ color: "#008000" }}><b>amazing, beautiful, creative, and wise is God.</b></h5>
          </p></b>
        </div>
      </div>

      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px', // Set a minimum height for the image container
      }}>
        <img 
          src={stlLink6}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink6;
