import React from 'react';
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';
import { ThemeProvider } from "react-bootstrap";
import Slides from './Components/slides';
import Image from 'react-bootstrap/Image';
import SecondSunFlower from "./images/second_sunflower.png";
import sunflowerLoge from './images/sunflower_logo.png';
import ThrivingImage from './images/thriving_image.png';
import Logo_Bottom from './images/logo_in_the_bottom.png';
import { Link } from 'react-router-dom';

function App() {
  return (
  <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint='xxs'
    >
    <div className="main">
      <Container className="home_page">
        <Row style={{backgroundColor:'black', textAlign:'center', paddingTop:'2%'}}>
          <h5>
            <img
              style={{ textAlign: "center", paddingBottom: "1%" }}
              src={sunflowerLoge}
              alt="sunflower logo">

            </img>
          </h5>
        </Row>
        <Row className="manu">
          <Col>
            <h5 style={{ textAlign: "center" }}>Home</h5>
          </Col>
          <Col>
           <h5 style={{ textAlign: "center" }}>
              <Link style={{color:'white'}} to='connectwithus'>Connect With Us </Link>
            </h5>
          </Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>Resources</h5>
          </Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>
              <Link style={{color: 'white'}} to='aboutus'>About Us</Link>
            </h5>
          </Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>Testimonies</h5>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "black" }} className="row_spaces">
          <Slides></Slides>
        </Row>
        <Row className="discription">
          <div style={{ padding: "5%", marginBottom: "10%" }}>
            <h2
              style={{
                textAlign: "center",
                color: "black",
                marginBottom: "6%",
              }}>
              <b>What is the Sunflower Tool?</b>
              <br></br>
              <small>
                It is a simple, visually-based spiritual tool for growing
                wholeness that starts with sunflower analogies. We can practice
                growing closer together in small communities where heart level
                care and communication become the norm.
              </small>
            </h2>
            <h4 style={{ color: "#4c81db", marginBottom: "5%", fontWeight:'bolder' }}>
              <ol>
                <li>Are you looking for deeper community life?</li>
                <br></br>
                <li>
                  Are you wanting to become more whole and see its effects in
                  your daily life?
                </li>
                <br></br>
                <li>
                  Do you want to know ways for growing inner strength and peace
                  in the harder times?
                </li>
              </ol>
            </h4>
            <h4 style={{ color: "black" }}>
              <p>
                If you answered yes to any of these questions, click here
                <Link
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    padding: "1%",
                    textAlign: "center",
                    marginLeft:'2%'
                  }}
                  to="sunflowerlink">
                  Sunflower Tool
                </Link>
              </p>
              <p>
                Meet the Four-Petal Sunflower with two overview looks. Check out
                the many button links to go deeper!
              </p>
            </h4>
          </div>
          <Row style={{ marginBottom: "15%" }}>
            <h4 style={{ color: "black", textAlign: "center" }}>
              <b>A Thriving Sunflower Community*</b> <br></br>
              <small style={{ color: "#4c81db", fontWeight:'bolder' }}>
                What does the glowing heart represent to you?
              </small>
            </h4>
            <Col className='paragraph1'>
              <h5 className='paragraph1_text'
                style={{
                  color: "black",
                  
                  paddingTop: "20%",
                  paddingBottom: "20%",
                  marginLeft:'8%'
                }}>
                <p>*A Sunflower Community is a</p>  
                <p>group of two or more disciples</p>
                <p>of Jesus Christ who practice</p>
                <p>ways of life that grow their</p>
                <p>spiritual roots deeper and deeper</p>
                <p>into God's love. They grow up</p>
                <p>together in maturity and wholeness</p>
                <p>by encouraging, caring, and</p>
                <p>and praying with one another.</p>
              </h5>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Image
                style={{ borderStyle: "solid", borderColor: "yellow" }}
                className="pageImg"
                src={ThrivingImage}
                alt="page_image"
              ></Image>
            </Col>
            <Col>
              <h3 style={{ paddingTop: "20%", textAlign: "center" }}>
                <p style={{ marginBottom: "15%" }}>
                  <a
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center",
                    }}
                    href="#">
                    Four-Petal Sunflower
                  </a>
                </p>
                <p style={{ marginBottom: "15%" }}>
                  <a
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center",
                    }}
                    href="#">
                    Immanuel Cycle
                  </a>
                </p>
                <p style={{ marginBottom: "15%" }}>
                  <a
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center",
                    }}
                    href="#">
                    Testimonies
                  </a>
                </p>
              </h3>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10%" }}>
            <h4 style={{ color: "black", textAlign: "center" }}>
              <b>10 Spiritual Keys for Thriving in Today's World </b> <br></br>
              <small style={{ color: "#4c81db", fontWeight:'bolder' }}>
                Is there a key you are most interested in?
              </small>
            </h4>
            <Col>
              <h5 className='paragraph2_text'
                style={{
                  color: "black",
                  textAlign: "center",
                  paddingTop: "20%",
                  paddingBottom: "20%",
                }}>
                <p>Abiding Givers are learning how</p> 
                <p>to live close to God. They are</p>
                <p>growing to be more like Jesus.</p> 
                <p>They are practicing resting in</p>
                <p>God and giving from their hearts.</p>
              </h5>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Image
                style={{ borderStyle: "solid", borderColor: "yellow" }}
                className="pageImg"
                src={SecondSunFlower}
                alt="page_image"
              ></Image>
            </Col>
            <Col>
              <h3 style={{ paddingTop: "20%", textAlign: "center" }}>
                <p style={{ marginBottom: "15%" }}>
                  <Link
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center"
                    }}
                    to='tenkeys'> Ten Keys
                    </Link>
                </p>
                <p style={{ marginBottom: "15%" }}>
                  <a
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center",
                    }}
                    href="#">
                    Giving Cycle
                  </a>
                </p>
                <p style={{ marginBottom: "15%" }}>
                  <a
                    style={{
                      backgroundColor: "#018cfe",
                      color: "yellow",
                      padding: "2%",
                      textAlign: "center",
                    }}
                    href="#">
                    Connect With Us
                  </a>
                </p>
              </h3>
            </Col>
          </Row>
          <div
            className="row_spaces"
            style={{
              paddingTop: "10%",
              paddingLeft: "3%",
              marginBottom: "1%",
              paddingBottom: "7%",
              color: "#018cfe",
            }}>
            <h4 style={{ marginBottom: "10%", textAlign: "center", fontWeight:'bolder' }}>
              "Spiritual growth consists most in the growth of the root, which
              is out of sight." <br></br>
              <p></p>
              -Matthew Henry, pastor and Bible commentator 1663-1714
              <br></br>
            </h4>
            <div style={{ textAlign: "center", color: "black" }}>
              <h4 style={{ marginBottom: "10%" }}>
                Repeated Spirit-led meditation and action steps change us from
                the inside out.
              </h4>
              <p style={{ marginBottom: "5%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    textAlign: "center",
                    padding: "1%",
                  }}
                  href="#" >
                  Discovery Cycle
                </a>
              </p>
              <p style={{ marginBottom: "20%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    textAlign: "center",
                    padding: "1%",
                  }}
                  href="#">
                  Lectio Cycle
                </a>
              </p>
            </div>
            <h4 style={{ textAlign: "center", fontStyle: "italic", fontWeight:'bolder' }}>
              Peace seems out of <br></br>
              reach<br></br>
              One cares for one, <br></br>
              hard places <br></br>
              We feel the human. <br></br>
            </h4>
              <b></b>
              <h4 style={{ marginTop: "2%", textAlign:'center' }}>
                -L. Wong <br></br>
                Southeast Asia
             </h4>
          </div>
          <Row
            style={{ color: "black", textAlign: "center", marginBottom: "20%" }}>
            <Col>
              <p style={{ marginBottom: "70%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    padding: "2%",
                    textAlign: "center",
                  }}
                  href="#" >
                  Nature Cycle
                </a>
              </p>
              <p style={{ marginBottom: "15%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    padding: "2%",
                    textAlign: "center",
                  }}
                  href="#">
                  Maturity Cycle
                </a>
              </p>
            </Col>
            <Col>
              <h4 className='paragraph3_text'>
                Practicing Cycles gives us <br></br>
                opportunities for Jesus to <br></br>
                shine into our hearts, bring <br></br>
                inner healing, free us to <br></br>
                become whole, and draw us <br></br>
                closer to one another through <br></br>
                the Holy Spirit.
              </h4>
            </Col>
            <Col>
              <p style={{ marginBottom: "70%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    padding: "2%",
                    textAlign: "center",
                  }}
                  href="#">
                  Green Cycle
                </a>
              </p>
              <p style={{ marginBottom: "15%" }}>
                <a
                  style={{
                    backgroundColor: "#018cfe",
                    color: "yellow",
                    padding: "2%",
                    textAlign: "center",
                  }}
                  href="#">
                  Wholeness Cycle
                </a>
              </p>
            </Col>
          </Row>
          <Row
            style={{ textAlign: "center", color: "black", marginBottom: "10%" }} >
            <h4 style={{ marginBottom: "5%" }}>
              The Four-Petal Sunflower visual and other Sunflower Tool visuals
              help connect our inner hearts to the pure heart of God.
            </h4>
            <Col>
              <Image className="sn" src={Logo_Bottom} alt="page_image"></Image>
            </Col>
            <h4 style={{ marginBottom: "10%", textAlign: 'center', marginTop:'5%' }}>
              <Link to={'ephesiansthree'}><u>Ephesians 3:14-21</u></Link><br>
              </br>
              <b>Paul's prayer is our prayer for you and us.</b>
            </h4>
            <h4>
              Hope you will share this website link with a friend or two and
              together consider connecting with us.
            </h4>
          </Row>
        </Row>
      </Container>
    </div>
  </ThemeProvider>
  );
}

export default App;
