// ImageTextComponent.js
import React from 'react';
import stlLink15 from "../images/for_sunFlowerToolLink/link15.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink15 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', maxWidth: '90%', marginLeft:'5%'}}>
      <div style={{color: "#008000", textAlign:'center', paddingTop:'5%'}}>
        <h3 style={{marginBottom:'3%'}}>
          <b>View #4: Maturity Cycle</b>
        </h3>
        <h5 style={{textAlign:'left', marginLeft:'10%'}}>This cycle gives us chances to practice <b>“Listening Love”</b> while using a<br></br>
          conversation tool about <b>maturity</b>. Consider how the sectors of each diagram<br></br>
          are part of growing in maturity.
        </h5>
      </div >
      <div>
        <Button style={{textAlign:'center' , color:'yellow'}} ><b>Maturity Cycle</b></Button>
      </div>
      <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src={stlLink15}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink15;
