// ImageTextComponent.js
import React from 'react';
import stlLink17 from "../images/for_sunFlowerToolLink/link17.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink17 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', maxWidth: '90%', marginLeft:'5%'}}>
      <div style={{color: "#008000", textAlign:'center', paddingTop:'5%'}}>
        <h3 style={{marginBottom:'3%'}}>
          <b>View #6: Lectio Cycle</b>
        </h3>
        <h5>This cycle gives us chances to practice Word of God Meditations.<br></br>
        </h5>
      </div >
      <div>
        <Button style={{marginTop:'3%', textAlign:'center', color:'yellow'}} ><b>Lectio Cycle</b></Button>
      </div>
      <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src={stlLink17}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink17;
