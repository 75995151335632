// ImageTextComponent.js
import React from 'react';
import stlLink19 from "../images/for_sunFlowerToolLink/image19.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink19 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', paddingLeft:'70px', paddingRight:'70px', paddingBottom:'20px'}}>
      <div style={{color: "#008000", textAlign:'center', paddingTop:'5%'}}>
        <h2 style={{marginBottom:"5%"}}>
          View #8: Theme Heart Logo Theme Love Scripture
        </h2>
        <h4 style={{textAlign: 'left'}}>
          The heart logo visual depicts the great gift of love we as communities can<br></br>
          experience as we “unwrap this gift into our hearts” and then live it out blessing<br></br>
          others. Apostle Paul’s prayer helps us pray this into our lives.
        </h4>
      </div >
      <div style={{ display: 'flex', maxWidth: '87%', height: 'auto', marginLeft:"7%" }}>
        <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img 
            src={stlLink19}
            alt="Description" 
            style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
           />
        </div>
        <div style={{ 
            flex: '1', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            overflow: 'hidden', // Prevent overflow
            textAlign: 'center' // Center text
          }}>
          <div style={{color: "#008000",maxWidth: '100%'}}>
            <h3>Ephesians 3:14-21</h3>
          </div>
        </div>
      </div>
      <div style={{color: "#008000",maxWidth: '100%', textAlign: 'left'}}>
        <p><b>1. Tap on the hyperlink* in your heart language. <u style={{paddingRight:"15px"}}>
        <a href=''>Ephesians 3:14-21</a></u> <u><a href=''>Kamay Place Holder</a></u></b>
        </p>
        <p>
          <b>2. Consider using it now or soon to say a prayer for your small community.</b>
        </p>
      </div>
    </div>
  );
};


export default ImageLink19;
