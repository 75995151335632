// ImageTextComponent.js
import React from 'react';
import stlLink7 from "../images/for_sunFlowerToolLink/stlImage7.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";

const ImageLink7 = () => {
  return (
    <div style={{
      display: 'flex',
      backgroundImage: `url(${stLbackground})`,
      backgroundSize: 'cover', // Ensure the background covers the entire div
      backgroundPosition: 'center', // Center the background image
      maxWidth: '100%',
      height: '70vh', // Full viewport height
      marginLeft: "5%",
      marginRight:'5%',
      alignItems: 'center', // Center contents vertically
      justifyContent: 'space-between', // Distribute space evenly
      boxSizing: 'border-box',
      padding: '0 20px', 
    }}>
      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        minHeight: '400px', // Set a minimum height for the text container
      }}>
        <div style={{
          color: "#008000",
          textAlign: "left",
          maxWidth: '100%',
        }}>
          <p>
            <b>When I gaze at sunflowers, I learn more of<br />
            who God is in <b>all His glory.</b></b><br />
          </p>
          <p>
            <b>
            I enjoy slowly meditating on God’s Word —<br />
            by myself and with others.
            </b>
          </p>
          <p>
            <b>
            The glorious beauty of these simple petals<br />
            and deep times in God’s Word speaks to<br />
            my heart of <b>God’s glory.</b><br />
            <a href='#'><b><u>Psalm 104:24</u></b></a>
            </b>
          </p>
          <p style={{ textAlign: 'left' }}>
            <b>
            <b>Slowing</b> down from my old “hurry pace” is<br />
            helping me. I am being transformed while<br />
            connecting to His glory.<br />
            <a href='#'><b><u>2 Corinthians 3:18</u></b></a>
            </b>
          </p>
        </div>
      </div>

      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px', // Set a minimum height for the image container
      }}>
        <img 
          src={stlLink7}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink7;
