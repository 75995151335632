// ImageTextComponent.js
import React from 'react';
import stlLink18 from "../images/for_sunFlowerToolLink/link18.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink18 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', maxWidth: '90%', marginLeft:'5%'}}>
      <div style={{color: "#008000", textAlign:'center', paddingTop:'5%'}}>
        <h3 style={{marginBottom:'3%'}}>
          <b>View #7: Green Cycle</b>
        </h3>
        <h5 style={{marginLeft: '10%', textAlign:'left'}}>This cycle uses a conversation tool to consider <b>healing</b> ways to greater personal<br></br>
          and community <b>wholeness</b>. An accompanying Forgiveness Tree visual offers us<br></br>
          helps for growing a forgiving heart.
        </h5>
      </div >
      <div>
        <Button style={{marginTop:'3%', textAlign:'center', color:'yellow'}} ><b>Green Cycle</b></Button>
      </div>
      <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src={stlLink18}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink18;
