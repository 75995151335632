import React from "react";

const ImageDisplay = ({ src, link }) => {
    return (
        <div style={{ flex: 1, padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={src}
                alt={<p>{link}</p>}
            />
            <div>{link}</div>
        </div>
    );
};

export default ImageDisplay;
