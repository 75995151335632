// ImageTextComponent.js
import React from 'react';
import stlLink3 from "../images/for_sunFlowerToolLink/link3.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink3 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', maxWidth: '100%'}}>
      <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src={stlLink3}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
      <div>
        <p style={{marginTop:'2%', textAlign:'right', marginRight:'10%', color: "#008000" }} > <b>Theme Scripture 

          <a style={{marginLeft:'3%'}} href='#'><b><u>Ephesians 3:14-21</u></b></a>
        </b>
        </p>
      </div>
    </div>
  );
};

export default ImageLink3;
