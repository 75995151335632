// ImageTextComponent.js
import React from 'react';
import stlLink8 from "../images/for_sunFlowerToolLink/stllinkimage8.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";

const ImageLink8 = () => {
  return (
    <div style={{
      display: 'flex',
      backgroundImage: `url(${stLbackground})`,
      backgroundSize: 'cover', // Ensure the background covers the entire div
      backgroundPosition: 'center', // Center the background image
      maxWidth: '100%',
      height: '60vh', // Full viewport height
      alignItems: 'center', // Center contents vertically
      justifyContent: 'space-between', // Distribute space evenly
      padding: '0 20px', // Keep horizontal padding, remove vertical padding
      boxSizing: 'border-box',
    }}>
      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        minHeight: '400px', // Set a minimum height for the text container
      }}>
        <div style={{
          color: "#008000",
          textAlign: 'left',
          maxWidth: '100%',
          padding: '0 10px', // Added some horizontal padding
        }}>
          
          <b>
          <p>
            
            The close-knit center flowers developing into seeds<br />
            remind me of <b>the sacred power of love unity — </b><br />
            
          </p>
          <ul>
              <li>love unity in the Godhead.</li>
              <li>love unity in our small community with God and<br></br>
                  each other.
              </li>
          </ul>
          <p><a href='#'><b><u>John 17:20-24</u></b></a></p>
          
          </b>
        </div>
      </div>

      <div style={{
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px', // Set a minimum height for the image container
      }}>
        <img 
          src={stlLink8}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink8;
