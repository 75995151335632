// ImageTextComponent.js
import React from 'react';
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";

const ImageLink26 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, paddingLeft:'50px', paddingRight:'50px', paddingBottom:'20px'}}>
      <div style={{textAlign:'center', paddingTop:'5%'}}>
        <h2 style={{marginBottom:"5%", textAlign:'left'}}>
          Endnotes:
        </h2>
        <h5 style={{textAlign: 'left', marginBottom:'5%'}}>
          1Credit: Wholeness aspects on four petals adapted from: C. Parker (personal communication, <br></br>
          February 10, 2023) shared hand drawn sketch Broken Whole Gospel Illustration. author <br></br>
          unknown; Corbett, S. & Fikkert, B. (2012). When Helping Hurts: How to Alleviate Poverty<br></br>
          without Hurting the Poor and Yourself. Moody Publishers. (pp. 54-57).
        </h5>
        <h5 style={{textAlign: 'left', marginBottom:'5%'}}>
          2Credit: John Piper.  For more understanding of the word  “glory” see his YouTube “What is<br></br>
          God’s Glory?”.  <u><a href='https://youtu.be/FA9xmKCkKnQ'>https://youtu.be/FA9xmKCkKnQ</a></u> <br></br>        
        </h5>
        <h5 style={{textAlign: 'left', marginBottom:'5%'}}>
          3Credit: Ed Khouri. For more understanding on this topic of “face” explore his book Becoming a<br></br>
          Face of Grace: Navigating Lasting Relationships with God. Illumify Media Global.(2021).     
        </h5>
        <h5 style={{textAlign: 'left', marginBottom:'5%'}}>
          4Ecclesiastes 3:1 New Living Translation.
        </h5>

        <h5 style={{textAlign: 'left', marginBottom:'5%'}}>
          5Credit: Ken Fish. For more understanding on this topic listen to his Becoming<br></br>
          People of the Spirit presentation. CD and Orbis App Free Resource. Orbis Ministries.
        </h5>
      </div >
    </div>
  );
};


export default ImageLink26;
