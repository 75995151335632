// ImageTextComponent.js
import React from 'react';
import stlLink16 from "../images/for_sunFlowerToolLink/link16.png";
import stLbackground from "../images/for_sunFlowerToolLink/stlbackground.png";
import { Button } from 'react-bootstrap';

const ImageLink16 = () => {
  return (
    <div style={{backgroundImage: `url(${stLbackground})`, textAlign:'center', maxWidth: '90%', marginLeft:'5%'}}>
      <div style={{color: "#008000", textAlign:'center', paddingTop:'5%'}}>
        <h3 style={{marginBottom:'3%'}}>
         <b>View #5: Wholeness Cycle</b>
        </h3>
        <h5>This cycle shows Jesus’ life journey in Holy Spirit influenced<br></br>
          communities5 as an important aspect of growing up in wholeness.<br></br>
        </h5>
      </div >
      <div>
        <Button style={{marginTop:'2%', textAlign:'center', color:'yellow'}} > <b>Wholeness Cycle</b></Button>
      </div>
      <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src={stlLink16}
          alt="Description" 
          style={{ maxWidth: '100%', height: 'auto' }} // Responsive image
        />
      </div>
    </div>
  );
};

export default ImageLink16;
